
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { EmpreendimentoService } from "@/core/services/cadastros";

@Component
export default class CfgRequisicao extends Vue {
  item: {
    id: number;
    empreendimentoId: number;
    empresaId: number;
    dataInicial: string;
    dataFinal: string;
    somenteExecutada: boolean;
  } = {
    id: 0,
    empreendimentoId: 0,
    empresaId: 0,
    dataInicial: "",
    dataFinal: "",
    somenteExecutada: false,
  };

  valid = true;
  empreendimentos: any = [];

  mounted() {
    const empreendimentoService = new EmpreendimentoService();
    empreendimentoService.ListarTudo().then(
      (res) => {
        this.empreendimentos = res.data.items;
        this.empreendimentos.sort((x,y) => (x.nome > y.nome) ? 1 : ((y.nome > x.nome) ? -1 : 0))
      },
      (err) => this.$swal("Aviso", err.message, "error")
    );
  }
 
  Visualizar() {
    if(!this.item.dataInicial || !this.item.dataFinal){
        this.$swal("Aviso", "Obrigatório preencher início e fim da data.", "warning");
        return true;
    }    
    let routeLink = this.$router.resolve({name: 'relRequisicao',
      query:{
        id: this.item.id.toString(),
        empreendimentoId: this.item.empreendimentoId ?  this.item.empreendimentoId.toString() : '0',
        empresaId:  this.item.empresaId ? this.item.empresaId.toString() : '0',
        dataInicial: this.item.dataInicial,
        dataFinal: this.item.dataFinal,
        somenteExecutada: this.item.somenteExecutada.toString()
      }
    });
    window.open(routeLink.href, '_blank');
  }
}
